<template>
  <div class="wrap">
    <div class="desc"
         v-if="checkList.length">选择题</div>
    <div class="checkList"
         v-if=" checkList.length">

      <div class="line">
        <Check ref="checkRef"
               v-for="(item,index) in checkList"
               :type="item.topic + ''"
               :answer.sync="item.answer"
               :questionId="item.question_id"
               :index="item.answer_id"
               @setAnwer="setAnwer"
               :key="index" />
      </div>
    </div>

    <div class="block"
         v-if="paperInfo.correct_type == 2"></div>
    <div class="wenda"
         v-if=" activeList.length">
      <!-- <div class="desc" style="margin-left: 0rem;">
        解答题
      </div>

      <Question ref="questionRef"
                v-for="(item,index) in activeList"
                :index="item.answer_id"
                :score.sync="item.score"
                :max_score="Number(item.question_score)"
                @setComment="setComment"
                :topic="item"
                :key="index" /> -->
      <!-- 
      <div class="desc"
           style="margin-left:0">
        评论
      </div>

      <el-input type="textarea"
                v-model="comment"
                placeholder="请输入你的评论..."
                :rows="5"
                maxlength="200"
                show-word-limit
                style="font-size:30rem"/> -->

    </div>
    <div class="desc">
      请选择填空和解答题答题照片然后上传。最多只能上传6张照片，每张照片不超过10M。
    </div>
    <div class="images">
      <div v-for="(item,index) in images"
           :key="index">
        <el-image :src="$addHeader(item)"
                  @click="uploadImage(index)"
                  :preview-src-list="[$addHeader(item)]"
                  v-if="item"
                  alt=""></el-image>
        <img v-else
             @click="uploadImage(index)"
             src="@/assets/code/upload.png"
             alt="">
        <img class="del"
             v-if="item"
             @click="delImage(index)"
             src="@/assets/icons/删除.png"
             alt="">
      </div>

      <div class="btn1"
           v-if="paperInfo.correct_type == 2"
           @click="submitAll">
        提交
      </div>

    </div>
    <div class="btn"
         v-if="paperInfo.correct_type != 2"
         @click="submit">
      提交
    </div>
  </div>
</template>

<script>
import { uploadImageAuto } from '@/api/upload.js'
import { savePaperImage } from '@/api/wholepaper.js'
import { continueDoPaper, phonePaper } from '@/api/my_paper.js'
import Check from './components/check.vue'
import Question from './components/question.vue'
export default {
  data () {
    return {
      images: ['', '', '', '', '', ''],
      checkList: [],
      activeList: [],
      flatList: {},
      comment: '',
      paperInfo: {
        correct_type: 1
      },
      screenHeight: window.innerHeight,
    }
  },
  components: {
    Check, Question
  },
  mounted () {
    console.log('screenHeight: ' + this.screenHeight);
    // correct_type == 2 学生自评
    let userInfo = {
      token: this.$route.query.token
    }
    window.localStorage.setItem("userInfo", JSON.stringify(userInfo))
    this.getPaperInfo()
  },
  methods: {
    async getPaperInfo () {
      let params = {
        user_paper_id: this.$route.query.id
      }
      const { data } = await continueDoPaper(params)

      this.images = data.list.other_answer ? JSON.parse(data.list.other_answer) : []
      var num = 6 - this.images.length
      if (num > 0) {
        for (let index = 0; index < num; index++) {
          this.images.push('')
        }
      }
      data.list.questions = data.list.questions.map(item => {
        item.question_big_score = 0;

        if (item.question_children && item.question_children.length) {

          let score = 0
          item.question_children = item.question_children.map(element => {
            score += Number(element.question_score);
            element.answer = []
            if (element.user_answer) {
              element.answer = element.user_answer.split(',')
            }
            return element
          });
          item.question_big_score = score;
        } else {
          item.answer = []
          if (item.user_answer) {
            item.answer = item.user_answer.split(',')
          }
        }
        return item;
      })

      this.paperInfo = data.list

      this.setFlatAndTopicNumber(this.paperInfo)

      // if (this.paperInfo.correct_type == 2) {
      let checkarr = [1, 2, 9, 4, 3]
      this.checkList = Object.values(this.flatList).filter(item => {
        return checkarr.includes(item.topic - 0)
      })
      var obj = {}
      this.checkList.forEach(item => {
        obj[item.answer_id] = item
      })
      this.checkList = Object.values(obj)

      this.activeList = Object.values(this.flatList).filter(item => {
        return !checkarr.includes(item.topic - 0) && Number(item.question_score)
      })
      var activeObj = {}
      this.activeList.forEach(item => {
        if (!item.score) {
          item.score = 0
        }

        if (!item.comment) {
          item.comment = ''
        }
        item.score = Number(item.score)
        activeObj[item.answer_id] = item
      })
      console.log('activeObj', activeObj)
      this.activeList = Object.values(activeObj)
      // }
    },
    async uploadImage (index) {
      if (this.images[index]) {
        return
      }
      const { data } = await uploadImageAuto()
      this.$set(this.images, index, data.info.name)
    },
    delImage (index) {
      this.$set(this.images, index, '')
    },
    async submit () {
      let upfile_arr = []
      this.images.forEach(item => {
        if (item) upfile_arr.push(item)
      })
      if (upfile_arr.length == 0) {
        this.$message.warning("请上传图片")
        return
      }
      let data = {
        upfile_arr,
        user_paper_id: this.$route.query.id
      }
      await savePaperImage(data)
      this.$message.success('上传成功')
    },
    setAnwer (id, answer) {
      var question = this.flatList[id]
      // 重复点击 取消选中
      if (question.answer.indexOf(answer) != -1) {
        question.answer.splice(question.answer.indexOf(answer), 1)
        return
      }
      question.topic = Number(question.topic)

      if (question.topic == 1) {
        question.answer = [answer];
      } else if ([2, 3, 4, 9].indexOf(question.topic) != -1) {
        //双选
        if (question.answer.length == 2 && question.topic == 4) {
          return
        } else if (question.answer.length == 5 && question.topic == 9) {
          return
        }
        question.answer.push(answer)
      }
    },
    setFlatAndTopicNumber (info) {
      // 扁平化 引用this的question 也就是说flatlist 变化 paperInfo.questions也要跟着变
      var list = this.flatQuestion(info.questions)
      var obj = {}
      list.forEach(item => {
        obj[item.question_id] = item
      });
      this.flatList = obj
    },
    flatQuestion (list) {
      var allList = [];
      list.forEach(element => {
        allList.push(element)
        if (element.question_children && element.question_children) {
          allList = allList.concat(...element.question_children);
        }
      });
      return allList;
    },
    async submitAll () {
      let data = {
        user_paper_id: this.$route.query.id,
        //选择题答案
        chs_answer: {},
        //图片
        other_answer: [],
        self_assessment: {},
        comment: this.comment
      }
      this.checkList.forEach(item => {
        data.chs_answer[item.answer_id] = item.answer.join(',')
      })
      console.log('this.activeList', this.activeList)
      this.activeList.forEach(item => {
        data.self_assessment[item.answer_id] = {
          score: item.score,
          comment: item.comment
        }
      })
      let imageArr = this.images.filter(item => item)
      data.other_answer = JSON.stringify(imageArr)
      await phonePaper(data)
      this.$message.success("试卷提交成功")
    },
    setComment (index, comment, score) {
      let question = this.activeList.find(item => item.answer_id == index)
      if (question) {
        question.comment = comment
        question.score = score
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.desc {
  font-size: 30rem;
  margin-left: 50rem;
  margin-top: 70rem;
  margin-right: 50rem;
  font-weight: 500;
  color: #666666;
}
.add_img {
  width: 100rem;
  height: 100rem;
  font-size: 20rem;
  font-weight: 500;
  background: #f6f6f6;
  border-radius: 5rem;
  text-align: center;
  color: #2196f3;
  border-radius: 5rem;
  img {
    width: 42rem;
    height: 34rem;
    margin-top: 19rem;
    margin-bottom: 6rem;
  }
}
.images {
  display: flex;
  flex-wrap: wrap;
  padding-left: 50rem;
  div {
    position: relative;
    margin-top: 77rem;
    margin-right: 50rem;
    img {
      height: 160rem;
      aspect-ratio: 1;
    }
    .el-image {
      height: 160rem;
      aspect-ratio: 1;
      margin-top: 0;
      margin-right: 0;
    }
    .del {
      position: absolute;
      top: -30rem;
      right: -20rem;
      width: 70rem;
      height: 70rem;
    }
  }
}
.btn {
  border-radius: 116rem;
  background: #2196f3;
  height: 90rem;
  color: #ffffff;
  font-weight: bold;
  font-size: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 77rem;
  // width: calc(100% - 154rem);
  // margin-left: 77rem;
  width: 100%;
  margin: 361rem, 100rem, 51rem, 100rem;
}

.btn1 {
  border-radius: 116rem;
  background: #2196f3;
  height: 90rem;
  color: #ffffff;
  font-weight: bold;
  font-size: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;
  // width: calc(100% - 154rem);
  width: 100%;
  margin: 361rem, 100rem, 51rem, 100rem;
}

.checkList {
  // width: calc(100vw - 200rem);
  margin-left: 40rem;
  margin-right: 40rem;
  margin-top: 46rem;
  padding: 38rem 20rem;

  background: #ffffff;
  box-shadow: 0px 1px 8px 1px rgba(170, 170, 170, 0.16);
  border-radius: 20rem 20rem 20rem 20rem;
}
.line {
  margin-top: 18rem;
}

.wrap {
  background: #ffffff;
  padding-bottom: 80rem;
}
.block {
  width: 100%;
  height: 77rem;
  background: #f6f6f6;
  margin-top: 77rem;
}
.wenda {
  // padding: 77rem 102rem;
  margin-left: 40rem;
  margin-right: 40rem;
  ::v-deep .el-textarea__inner {
    border: none;
  }
}
</style>